import React, { useEffect } from 'react'
import { isEmpty } from 'lodash';
import { sanitize } from "../../../utils/functions";
import './_style.scss';
import Img from "gatsby-image";

// Import Swiper React components
import Swiper from 'swiper/bundle';
// Import Swiper styles
import 'swiper/swiper.scss';




const SliderOr = ( props ) => {	  // constructor() {
	  //   this.state = true
	  // }

		useEffect(()=>{

			function initSwiper(){
				new Swiper('.swiper-container', {
					init: true,
					observer: true,
					observeParents: true,
					updateOnImagesReady: true,
					spaceBetween: 32,
					slidesPerView: 1.5,
					direction: 'horizontal',
					loopFillGroupWithBlank: true,
					breakpoints: {
						700: { // --- md
							slidesPerView: 3,
							slidesPerColumn: 1,
						},
					}
				});
			}
			initSwiper();
			window.addEventListener('load', initSwiper);


			const swiperContainers = document.querySelectorAll('.comp-slider-or .swiper-container');
			function resizeSwipers(){
				swiperContainers.forEach(container => {
					// altezza .swiper-container e nextElement
					var containerHeight = container.clientHeight;
					// margin-top dell'elemento successivo
					container.nextElementSibling.style.marginTop = (containerHeight + 40) + 'px';
				})
			}
			resizeSwipers();
			window.addEventListener('resize', resizeSwipers);


		},[]) //notice the empty array here


			const mainSlider = props.data;
			const sliderId = 'slider-or-' + Math.floor(Math.random() * 9999);


			return ! isEmpty( mainSlider && mainSlider[0].slides ) ? (

				<section id={ sliderId } className="comp-slider-or container">

					{ ! isEmpty( mainSlider ) ? (
						mainSlider.map(
							( pagePost, pageIndex ) => (

								<React.Fragment key={pageIndex}>
								{ pagePost.slides.map(
									( post, index ) => {

										return (
											<div key={ index } className={'blocco'}>

													{ post.titolo ? (
														<h2 className="titolo h4">{ post.titolo }</h2>
													) : ('') }


													{ ! isEmpty( post.gallery ) ? (
														<div className="swiper-container">
															<div className="swiper-wrapper">

																{ post.gallery.map(
																	( singola, index ) => {
																		return(
																			<div className="swiper-slide"
																				key={ index }
																			>
																				<Img
																				fluid={ singola.sourceUrlSharp.childImageSharp.fluid }
																				alt={ singola.altText ? singola.gallery.altText : 'Immagine' }
																				loading="eager"
																				/>
																			</div>
																		)
																	}
																) }

															</div>
														</div>
													) : ('') }


													{ post.testo ? ( // valuta uso di react-html-parser -> wysiwyg fa già escape
														<div className="testo" dangerouslySetInnerHTML={{__html: sanitize(post.testo) }}>
														</div>
													) : ('') }

											</div>
										)
									}
								) }
								</React.Fragment>

							)
						)
					) : null }

				</section>

			) : ('');




	}






export default SliderOr;
