
import React from 'react';

/* eslint-disable */
import Img from 'gatsby-image';
/* eslint-enable */

import Hero from "./../sections/hero";
import "bootstrap/js/dist/carousel.js";

import DestraSinistra from "./../sections/destra-sinistra";
import SliderOr from "./../sections/slider-or";

import { useStaticQuery, graphql } from "gatsby";
import { isEmpty } from 'lodash';
import './_style.scss';
import { sanitize } from "../../utils/functions";

const Page = ( props ) => {

	const { data } = props;
	const hero = data.acfPagine.hero;
	const sliderOr = data.acfPagine.compSlider;
	const compDxSx = data.acfPagine.compDxSx;


	/* eslint-disable */
	const imgData = useStaticQuery(graphql`
        query {
            file(relativePath: {eq: "default/default.jpg"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
	`);
	/* eslint-enable */

	console.log('props', props);

	const pageId = props.data.databaseId

	// COOKIE POLICY PAGE
	if(pageId === 497){
		return (
			<>
				{ ! isEmpty( data )  ? (
					<div className="page-container">
						{
							! isEmpty( data.title )  ? (
							<h1 dangerouslySetInnerHTML={{__html: sanitize( data?.title )}}/>
							) : null
						}

						<section className="page-content" dangerouslySetInnerHTML={{__html: props.data.content }} />

					</div>
				) : (
					'Loading...'
				) }
			</>
		);

	} else {

		return (
			<>
				{ ! isEmpty( data )  ? (
					<div className="page-container">
						{
							! isEmpty( data.title )  ? (
							<h1 dangerouslySetInnerHTML={{__html: sanitize( data?.title )}}/>
							) : null
						}
						<section className="page-content">
							{/* Uncomment this if you need featured image to be displayed here*/}
							{ ! isEmpty( data.featuredImage ) ? (
								<Img className="w-100" fluid={data.featuredImage.node.sourceUrlSharp.childImageSharp.fluid} alt={ data.altText ? data.altText : data.title } />
							) : null }

							{ ! isEmpty( data.content ) ? (
								<div
									dangerouslySetInnerHTML={ {
										__html: data.content,
									} }
								/>
							) : null }
						</section>

						<Hero className="mb-9vw" data={ hero }/>
						<SliderOr className="mb-9vw" data={ sliderOr }/>
						<DestraSinistra data={ compDxSx }/>

					</div>
				) : (
					'Loading...'
				) }
			</>
		);
	}


};

export default Page;
